import Wallet from "Stores/Wallet/Wallet";
import { IWalletData } from "./Wallet/IWalletInterface";
export default class StoreWorflow {
	private static instance: StoreWorflow;

	private constructor() {
		StoreWorflow.instance = this;
		this.initEvents();
	}

	public static getInstance() {
		return this.instance ?? new this();
	}

	public closeOnTopLayouts() {
	}

	private initEvents() {
		this.onWalletChange();
	}

	private onWalletChange() {
		Wallet.getInstance().onChange(async (walletData: IWalletData) => {
		});
	}

	private async onRefreshWallet(walletData: IWalletData, tokenUserAddress: string) {
		if (tokenUserAddress === walletData.userAddress) {
		}
	}

	private async onChangeAccount(walletData: IWalletData, tokenUserAddress: string) {
		if (tokenUserAddress !== walletData.userAddress && Boolean(tokenUserAddress)) {
		}
	}

	public disconnect() {
		Wallet.getInstance().disconnect();
	}
}

