import Torus from "@toruslabs/torus-embed";
import { SecretType, WindowMode } from "@venly/connect";
import { Venly, VenlySubProviderOptions } from "@venly/web3-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Config from "Configs/Config";
import { ethers } from "ethers";
import { EventEmitter } from "events";
import ThemeMode from "Stores/ThemeMode";
// import Toasts from "Stores/Toasts";
import BigNumber from "Services/BigNumber";
import Web3Modal, { providers } from "web3modal";
import IWalletInterface, { IWalletData } from "../IWalletInterface";

export const Web3ModalWalletType = {
	WALLET_CONNECT: providers.WALLETCONNECT.id,
	METAMASK: providers.METAMASK.id,
	VENLY: "custom-venly",
};
export interface IWallet {
	userAddress: string | null;
	balance: BigNumber | null;
	chainId: number | null;
	provider: ethers.providers.Web3Provider | null;
}

export default class Web3ModalWallet implements IWalletInterface {
	private static ctx: Web3ModalWallet;
	private static web3Modal: Web3Modal;
	private removeEvents = () => {};

	private walletData: IWallet | null = null;
	private readonly event = new EventEmitter();

	public constructor() {
		if (Web3ModalWallet.ctx) return Web3ModalWallet.ctx;
		Web3ModalWallet.ctx = this;
		return Web3ModalWallet.ctx;
	}

	public getWalletData(): IWalletData {
		return (
			this.walletData ?? {
				userAddress: null,
				balance: null,
				provider: null,
			}
		);
	}

	public static getInstance() {
		if (!Web3ModalWallet.ctx) new this();
		return Web3ModalWallet.ctx;
	}

	public async connectTo(type: string, idpHint?: string): Promise<Web3ModalWallet> {
		await this.disconnect();
		try {
			// if (type === Web3ModalWalletType.VENLY && idpHint && (Venly as any).subProvider) {
			// 	(Venly as any).subProvider.options.authenticationOptions.idpHint = idpHint;
			// }
			const instance = await Web3ModalWallet.getWeb3Modal(true, idpHint).connectTo(type);
			await this.setupProvider(instance);
		} catch (e) {
			await this.disconnect();
			if (type === Web3ModalWalletType.VENLY) {
				// Toasts.errorToast("toast.sign_in.venly_error");
				window.location.reload();
			}
			console.warn(e);
		}

		return this;
	}

	public async connect(): Promise<Web3ModalWallet> {
		try {
			const instance = await Web3ModalWallet.getWeb3Modal().connect();
			await this.setupProvider(instance);
		} catch (e) {
			await this.disconnect();
			console.warn(e);
		}

		return this;
	}

	private async setupProvider(instance: any) {
		const provider = new ethers.providers.Web3Provider(instance, "any");
		this.initEvents(instance, provider);
		if (!provider) throw new Error("provider not found");
		await this.changed(provider);
	}

	public async disconnect() {
		try {
			if ((Venly as any).subProvider && (await Venly.checkAuthenticated()).isAuthenticated) {
				await Venly.connect()?.logout();
			}
			this.walletData = null;
			Web3ModalWallet.getWeb3Modal().clearCachedProvider();
			this.changed(null);
			this.removeEvents();
			return;
		} catch (e) {
			console.warn(e);
		}
	}

	public onChange(callback: (web3WalletData: IWallet) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public autoConnect(): void {
		if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) {
			this.connect();
		}
	}

	private async changed(provider: ethers.providers.Web3Provider | null) {
		const userAddress: string | null = (await provider?.listAccounts())?.[0] ?? null;
		const chainId = (await provider?.getNetwork())?.chainId ?? null;
		let balance = null;

		if (userAddress && provider) {
			balance = BigNumber.from((await provider.getBalance(userAddress)).toString());
		}

		this.walletData = {
			userAddress,
			chainId,
			balance,
			provider,
		};

		this.event.emit("change", this.walletData);
	}

	private static getWeb3Modal(isNew: boolean = false, idpHint?: string) {
		if (Web3ModalWallet.web3Modal && !isNew) return Web3ModalWallet.web3Modal;
		Web3ModalWallet.web3Modal = Web3ModalWallet.newWeb3Modal(idpHint);

		return Web3ModalWallet.web3Modal;
	}

	private static newWeb3Modal(idpHint?: string) {
		return new Web3Modal({
			network: Config.getInstance().get().blockchain.ethereum.network,
			cacheProvider: true,
			providerOptions: Web3ModalWallet.getProviderOptions(idpHint),
			theme: ThemeMode.getInstance().type,
		});
	}

	private initEvents(instance: any | null, provider: ethers.providers.Web3Provider | null): void {
		this.removeEvents();
		const anyChanged = () => {
			this.changed(provider);
		};

		instance.on("accountsChanged", anyChanged);
		instance.on("chainChanged", anyChanged);
		instance.on("connect", anyChanged);
		instance.on("disconnect", anyChanged);
		this.removeEvents = () => {
			if (instance.removeAllListeners) {
				instance.removeAllListeners();
			}
		};
	}

	private static getProviderOptions(idpHint?: string) {
		const options: any = {};
		const config = Config.getInstance().get().wallet.ethereum.web3modal.providers;

		if (config.walletConnect.enabled) {
			options.walletconnect = {
				package: WalletConnectProvider,
				options: {
					rpc: {
						[Config.getInstance().get().blockchain.ethereum.chainId]: Config.getInstance().get().blockchain.ethereum.rpc,
					},
				},
			};
		}

		if (config.torus.enabled) {
			options.torus = {
				package: Torus,
			};
		}

		if (config.binanceChainWallet.enabled) {
			options.binancechainwallet = {
				package: true,
			};
		}

		if (config.venly.enabled) {
			options["custom-venly"] = this.getCustomVenly(idpHint!);
		}
		return options;
	}

	private static getCustomVenly(idpHint: string) {
		return {
			display: {},
			package: Venly,
			options: {
				clientId: Config.getInstance().get().wallet.ethereum.venly.clientId,
				secretType: Config.getInstance().get().blockchain.ethereum.network.toUpperCase() as SecretType,
				environment: Config.getInstance().get().wallet.ethereum.venly.environment,
				authenticationOptions: { idpHint, windowMode: Config.getInstance().get().wallet.ethereum.venly.windowMode as WindowMode },
				skipAuthentication: true,
			},
			connector: async (ProviderPackage: typeof Venly, options: VenlySubProviderOptions) => {
				try {
					const provider = await ProviderPackage.createProviderEngine(options);
					return provider;
				} catch (error) {
					return;
				}
			},
		};
	}

	public async signMessage(message: string): Promise<string> {
		try {
			if (!this.getWalletData().userAddress) {
				Promise.reject("User connected");
			}
			const signer = this.getWalletData().provider.getSigner();
			return await signer?.signMessage(message);
		} catch (err) {
			return Promise.reject(err);
		}
	}
}
