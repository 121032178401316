// import BaseApiService from './BaseApiService';

import { NetworkType } from "@airgap/beacon-sdk";
import axios from "axios";
import Config from "Configs/Config";

// type IProps = {
//   address: string,
// }
// type IState = {
//   nftsLinks: string[];
//   gateway: string;
//   accountInfo: any;
// }

// export default class QuerryNFTOnWallet<IProps, IState> {
  
//   constructor(props: IProps) {
//     state: { 
//       nftsLinks: [];
//       gateway: 'gateway.ipfs.io';
//       accountInfo: {};
//     };
//   }
//   }

//     await axios
//     .get(`https://api.tzkt.io/v1/tokens/balances?account=${address}`,)
//     .then(response => (this.state.accountInfo = response.data))
//     for(let i = 0; i < accountInfo.length; i++) {
//       const WrongURI = accountInfo[i].token.metadata.displayUri
//       const URI = `https://${gateway}/ipfs/` + WrongURI.substring(7)
//       nftsLinks.push(URI)
//     }
//     return nftsLinks;


// }




const QuerryNFTOnWallet = async (address: string) : Promise<string[]> => {
    
    let nftsLinks: string[] = [];
    const gateway = 'dweb.link'
    let accountInfo: any;
    const network = Config.getInstance().get().blockchain.tezos.network as NetworkType

    await axios
    .get(`https://api.${network}.tzkt.io/v1/tokens/balances?account=${address}`,)
    .then(response => (accountInfo = response.data))
    for(let i = 0; i < accountInfo.length; i++) {
      const WrongURI = accountInfo[i].token.metadata.artifactUri
      const URI = `https://${gateway}/ipfs/` + WrongURI.substring(7)
      console.log(WrongURI)
      console.log(URI)
      nftsLinks.push(URI)
    }
    return nftsLinks;
    
  }

export default QuerryNFTOnWallet