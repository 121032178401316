import React from 'react'
import './style.scss'

import QuerryNFTOnWallet from 'Api/tzkt'

type IProps = {
  Address: string
  ShowPopup: Function
  SendNFTLink: Function
}

type IState = {
  isShowing: boolean;
  NFTImageLinkList2: string[];
}

export default class NFTPopup extends React.Component<IProps, IState> {

  constructor(props: IProps){
    super(props)
    this.state = {
      isShowing: true,
      NFTImageLinkList2: [""],
    }
  }

  override async componentDidMount(): Promise<void> {
    const list = await QuerryNFTOnWallet(this.props.Address);
    this.setState({NFTImageLinkList2: list});
  }


  override render() {
    return (
      <>
      {
        this.state.isShowing === true ? 
          <div id="nft_popup">
            <span id="close" onClick={()=> this.props.ShowPopup()}>&times;</span>
            <div id="nft_display">
              <div>{this.state.NFTImageLinkList2.toString()}</div>
              {this.state.NFTImageLinkList2.map((nftImageLink: string, index: number)=>{
                return (
                <img className="nft_img" onClick={() => this.props.SendNFTLink(nftImageLink)} key={index} src={nftImageLink} alt="nft"/>
              )})}
            </div>
        </div>
        : <></>
      }
      </>
    )
  }

  showPopUp = () => {
    this.setState((isShowing) => ({
      isShowing:!isShowing,
    }))}

}