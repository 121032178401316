import React, { Suspense, useState } from 'react'

import './style.scss'
import Wallet from "Stores/Wallet/Wallet";

// Three
import { Canvas } from '@react-three/fiber';
import { FPSControls } from "react-three-fpscontrols";


// Prefabs
import { Skybox } from 'Components/Elements/SkyBox/Skybox';
import Template from 'Components/Materials/Scene/Template';
import NFTPopup from 'Components/Materials/NFTPopup/NFTPopup';
import img from './AddNFT.jpg';


// // ViewSetup
// import ViewSetup from "./Pages/ViewGallery/ViewSetup";

type ViewState = "select" | "creation" | "viewing"



const CreationSetup = (props:{viewState: ViewState, setTexture: Function}) => {

  const [isChoosing, setIsChoosing] = useState<boolean>(false)
  const [nftLink, setNftLink] = useState<string>(img)
  const address = Wallet.getInstance().getWalletData().userAddress

  const showPopup = function(key?: number): boolean {
    setIsChoosing(!isChoosing)
    return isChoosing;
  }

  const sendNFTLink = (link: string) => {
    setNftLink(link)
    showPopup()
  }

  return (
    <>
    <Canvas camera={{ position: [1, 2, 5], fov: 50 }}>
      <Suspense fallback={null}>
      <Skybox/>
      <ambientLight intensity={0.5} />
        <Template showPopup={showPopup} nftLink={nftLink} isChoosing={isChoosing} viewState={props.viewState} setOrGetTexture={props.setTexture}/>
        <FPSControls
          camProps={{
            makeDefault: true,
            fov: 80,
            position: [0, 2.537, 0.7]
          }}
          orbitProps={{
            target: [0, 2.537, 0]
          }}
          enableKeyboard
        />
      </Suspense>
      </Canvas>
      {
        isChoosing ? <NFTPopup Address={address ?? "test"} ShowPopup={showPopup} SendNFTLink={sendNFTLink}/>
        : null
      }    
  </>
)
}

export default CreationSetup





//Class type:

// type IProps = {}
// type IState = {
//   camera: THREE.PerspectiveCamera,
//   gl: THREE.WebGLRenderer,
// }

// export default class CreationSetup extends React.Component<IProps, IState> {
//   constructor(props){
//         super(props);
//         this.state = {
//           camera: useThree(),
//           gl: useThree((state) => state.gl),
//         } 
//       }

//   public override render(): JSX.Element {
//     console.log("testing")
//     return (
//       <>
//         <Suspense fallback={null}>
//         <Skybox/>
//         <ambientLight intensity={0.5} />
//           <Template />
//           <FPSControls
//             camProps={{
//               makeDefault: true,
//               fov: 80,
//               position: [0, 2.537, 0.7]
//             }}
//             orbitProps={{
//               target: [0, 2.537, 0]
//             }}
//             enableKeyboard
//           />
//         </Suspense>
//     </>
//   )}
// }