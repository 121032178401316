import Web3ModalWallet from "Services/Wallet/Ethereum/Web3ModalWallet";
import IWalletInterface from "Services/Wallet/IWalletInterface";
import WalletFactory from "Services/Wallet/WalletFactory";
import Config from "Configs/Config";
import BeaconWallet from "Services/Wallet/Tezos/BeaconWallet";

export enum EWalletType {
	WEB3 = "web3",
	BEACON = "beacon",
}

export default class Wallet {
	private static ctx: Wallet;
	private walletFactory: IWalletInterface;
	private wallets = new Map<EWalletType, { new (): IWalletInterface }>();

	private constructor(walletType: EWalletType) {
		this.wallets.set(EWalletType.WEB3, Web3ModalWallet);
		this.wallets.set(EWalletType.BEACON, BeaconWallet);
		this.walletFactory = WalletFactory.create(this.wallets.get(walletType)!);
		this.walletFactory.autoConnect();
		Wallet.ctx = this;
	}

	public static getInstance() {
		if (!this.ctx) new this(Config.getInstance().get().wallet.type as EWalletType);
		return Wallet.ctx.walletFactory;
	}
}
