import BigNumberJs from "bignumber.js";

export default class BigNumber {
	constructor(private bigNumber: BigNumberJs) {}

	public formatUnits(decimal: number = 18) {
		return this.bigNumber.div(10 ** decimal).toString();
	}

	public formatUnitsAndTruncate(significantDecimals: number = 18, decimal: number = 2) {
		const nbrInString = this.formatUnits(significantDecimals);
		const nbrExploded = nbrInString.split(".");
		if (nbrExploded[1]) {
			nbrExploded[1] = nbrExploded[1].concat("00");
			return `${nbrExploded[0]}.${nbrExploded[1]?.substring(0, decimal)}`;
		}
		return `${nbrExploded[0]}.00`
	}

	public from(value: string, unitName: any) {
		return BigNumber.from(value, unitName);
	}

	public static from(value?: string, base?: number) {
		if (!value) return new BigNumber(new BigNumberJs(0));
		if (base) return new BigNumber(new BigNumberJs(value).times(10 ** base));
		return new BigNumber(new BigNumberJs(value));
	}

	public getBigNumber() {
		return this.bigNumber;
	}

	public add(other: BigNumber) {
		return new BigNumber(this.bigNumber.plus(other.getBigNumber()));
	}

	public sub(other: BigNumber) {
		return new BigNumber(this.bigNumber.minus(other.getBigNumber()));
	}

	public mul(other: BigNumber | number) {
		if (other instanceof BigNumber) {
			return new BigNumber(this.bigNumber.times(other.getBigNumber()));
		}
		return new BigNumber(this.bigNumber.times(other));
	}
	public div(other: BigNumber) {
		return new BigNumber(this.bigNumber.div(other.getBigNumber()));
	}

	public eq(other: BigNumber) {
		return this.bigNumber.eq(other.getBigNumber());
	}

	public gt(other: BigNumber) {
		return this.bigNumber.gt(other.getBigNumber());
	}

	public gte(other: BigNumber) {
		return this.bigNumber.gte(other.getBigNumber());
	}

	public lt(other: BigNumber) {
		return this.bigNumber.lt(other.getBigNumber());
	}

	public lte(other: BigNumber) {
		return this.bigNumber.lte(other.getBigNumber());
	}

	public isZero() {
		return this.bigNumber.isZero();
	}

	public toNumber(decimal: number = 18) {
		return this.bigNumber.div(10 ** decimal).toNumber();
	}

	public removeDecimals(decimal: number = 6) {
		return new BigNumber(this.bigNumber.idiv(10 ** decimal));
	}

	public toInverse() {
		throw new Error("Method not implemented.");
	}

	public toString() {
		return this.bigNumber.toString();
	}
}
