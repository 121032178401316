import React from "react";
import './style.scss';

import GlobalContext, { defaultContext } from "./GlobalContext";
// import MainRoutes from "./MainRoutes";

import CreationSetup from "./Pages/CreateGallery/CreationSetup";

import Wallet from "Stores/Wallet/Wallet";
import ViewSetup from "./Pages/ViewGallery/ViewSetup";

type ViewState = "select" | "creation" | "viewing"


type IProps = {};

type IState = {
	viewState: ViewState
	finalNftTextureList: THREE.Texture[] | undefined
};


export default class Main extends React.Component<IProps, IState>{
	constructor(props: IProps) {
		super(props);
		this.state = {
			viewState: "select",
			finalNftTextureList: undefined,
		};
		
	}


	private setViewState = async(newState: ViewState) => {
		// No msg if no wallet installed
		await Wallet.getInstance().connect()
		console.log(`Gallery ${this.state.viewState}`)
		this.setState({viewState: newState});
	}


	private setFinalNftTextureList = (textureList: THREE.Texture[]) => {
		this.setState({finalNftTextureList: textureList})
	}

	getTextureList = (): THREE.Texture[] | undefined => {
		return this.state.finalNftTextureList;
	}

	public override render(): JSX.Element {
		return (
			<>
			<GlobalContext.Provider value={defaultContext}>
				{
      				this.state.viewState === "creation" ? 
					<>
						<CreationSetup viewState={this.state.viewState} setTexture={this.setFinalNftTextureList}/>
						<button id="view_button" onClick={() => this.setState({viewState: "viewing"})}>View your Gallery</button>
					</>
      				: this.state.viewState === "viewing" ?
					  <ViewSetup setViewState={this.setViewState} viewState={this.state.viewState} getTexture={this.getTextureList}/>

					: this.state.viewState === "select" ?
					<div id="menu">
					  	<h1>Metanoia POC</h1>
					  	<button id="start_game" onClick={() => this.setViewState("creation")}>Create your Gallery</button>
						<button onClick={() => this.setViewState("viewing")}>View your Gallery</button>
					</div>
					: <></>

				}
				</GlobalContext.Provider>
			</>
		);
	}
}

