import React, { Suspense } from "react";

// Three
import { Canvas } from "@react-three/fiber";
import { Physics } from "@react-three/cannon";

// Prefabs
import Template from 'Components/Materials/Scene/Template';

// import { Player } from "../prefabs/Player";
import { Skybox } from 'Components/Elements/SkyBox/Skybox';
import Controls from "Components/Materials/PlayerControl/controls"

//TO REMOVE
import img from './AddNFT.jpg';

type ViewState = "select" | "creation" | "viewing"


const ViewSetup = (props:{setViewState: Function, viewState: ViewState, getTexture: Function}) => {

  const showPopup = function(key?: number): boolean {
    return false;
  }
  
    return (
      <Canvas camera={{ position: [1, 2, 5], fov: 50 }}>
        <Suspense fallback={null}>
        <Skybox />
        {/** Lighting */}
        {/* <directionalLight position={[3, 0, 3]} intensity={0.5} castShadow />
        <directionalLight position={[-3, 0, -3]} intensity={0.5} castShadow /> */}
        <ambientLight intensity={0.5} />
        <Physics
          gravity={[0, 10, 0]}
          tolerance={0}
          iterations={50}
          broadphase={"SAP"}
        >
          <Controls />
          <Template showPopup={showPopup} nftLink={img} isChoosing={false} viewState={props.viewState} setOrGetTexture={props.getTexture}/>
        </Physics>
        </Suspense>
      </Canvas>
    );
  };
  

export default ViewSetup;



// Class type:

// type IProps = {}
// type IState = {
//   camera: THREE.PerspectiveCamera,
//   gl: THREE.WebGLRenderer,
// }


// export default class ViewSetup extends React.Component<IProps, IState> {
  
// 	public constructor(props: IProps) {
// 		super(props);
// 		this.state = {
// 			camera: useThree()

// 		};
// 	}

//   override render() {
//     return (
//       <div>ViewSetup</div>
//     )
//   }
// }