import React, { useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { CubeTextureLoader } from "three";

export const Skybox = () => {
  const { scene } = useThree();
  const loader = new CubeTextureLoader();
 
    console.log("sky")
    
    const texture = 
      loader.load([
        "left.jpg",
        "right.jpg",
        "top.jpg",
        "bottom.jpg",
        "back.jpg",
        "front.jpg"
      ]);
      scene.background = texture;

  return <></>;
};
