import React from 'react'

type Iprops = {
  number: number;
  position: number[];
  ShowPopup: Function;
  texture: THREE.Texture;
}

type IState = {
}

export default class NFTTemplate extends React.Component<Iprops, IState> {

  constructor(props: Iprops) {
    super(props);
    this.state = {
    };
  }

  // texture: THREE.Texture = useLoader(Three.TextureLoader, img);




  getNFT = (key: number) => {  
    this.props.ShowPopup(key)
  }


  override render() {
    return (
      <mesh 
      onClick={() => this.getNFT(this.props.number)}
      position={this.props.position}>
        <boxGeometry args={[ 2.3, 3, 0.01 ]}/>
        <meshStandardMaterial 
        attach="material" map={this.props.texture}
        />
      </mesh>
    )
  }
}
