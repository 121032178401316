import React, { useEffect, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import NFTTemplate from './NFTTemplate/NFTTemplate';
import { useLoader } from '@react-three/fiber';
import * as Three from 'three';

type ViewState = "select" | "creation" | "viewing"


const Template = (props:{showPopup: Function, isChoosing: boolean, nftLink: string, viewState: ViewState, setOrGetTexture: Function}) => {
  
  const textures: THREE.Texture[] | undefined = props.viewState === "viewing" ? props.setOrGetTexture() : undefined;

  const { nodes, materials } = useGLTF('template.glb');
  const texture : THREE.Texture = useLoader(Three.TextureLoader, props.nftLink)
  const [currentNFT, setCurrentNFT] = useState<number>(-1)
  const [isChoosingNFT, setIsChoosingNFT] = useState<boolean>(false)
  const [nftTextureList, setNftTextureList] = useState<THREE.Texture[]>(textures ?? [texture, texture, texture,texture, texture, texture, texture, texture]);

  useEffect(() => {
  if(props.viewState === "creation"){
      if(typeof props.nftLink === 'string' && !props.isChoosing){
        console.log(currentNFT)
        const tempNftLink = props.nftLink
        let newList: THREE.Texture[] = nftTextureList
         // eslint-disable-next-line react-hooks/rules-of-hooks
        newList[currentNFT] = useLoader(Three.TextureLoader, tempNftLink)
        setNftTextureList(newList);
        props.setOrGetTexture(newList);
        setCurrentNFT(-1)
    }
    if(!props.isChoosing) setCurrentNFT(-1)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nftLink, currentNFT, props.isChoosing, nftTextureList, props.setOrGetTexture])


  const showPopup = (key: number) =>  {
    const isOpen: boolean = props.showPopup(key)
    setIsChoosingNFT(isOpen)
    setCurrentNFT(key)
  }
  


  return (
    <group dispose={null}>
      <mesh geometry={nodes.Floor.geometry} material={materials['Material.002']} position={[14.08, 0, 5.5]} scale={[10, 0.3, 10]} />
      <mesh geometry={nodes.Wall_front.geometry} material={materials['Material.001']} position={[14.16, 0, 5.7]} scale={[0.2, 2.08, 6.18]} />
      <mesh geometry={nodes.Wall_left_1.geometry} material={materials['Material.002']} position={[15.94, 0, 8.19]} rotation={[0, 1.57, 0]} scale={[0.2, 2.08, 3.86]} />
      <mesh geometry={nodes.Wall_left_2.geometry} material={materials['Material.002']} position={[5.74, 0, 8.19]} rotation={[0, 1.57, 0]} scale={[0.2, 2.08, 3.86]} />
      <mesh geometry={nodes.Wall_right_1.geometry} material={materials['Material.002']} position={[5.74, 0, -9.19]} rotation={[0, 1.57, 0]} scale={[0.2, 2.08, 3.86]} />
      <mesh geometry={nodes.Wall_right_2.geometry} material={materials['Material.002']} position={[15.94, 0, -9.19]} rotation={[0, 1.57, 0]} scale={[0.2, 2.08, 3.86]} />
      <mesh geometry={nodes.LogoLockup.geometry} material={materials.LogoLockup} position={[14.85, 2.42, -1.42]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[3, 1, 3]} />
      {/* NFT templates */}
      <NFTTemplate key={0} number={0} texture={nftTextureList[0] || texture} ShowPopup={showPopup} position={[13.26, 2.32, 6.93]} />
      <NFTTemplate key={1} number={1} texture={nftTextureList[1] || texture} ShowPopup={showPopup} position={[9.92, 2.32, 6.9]} />
      <NFTTemplate key={2} number={2} texture={nftTextureList[2] || texture} ShowPopup={showPopup} position={[-0.18, 2.32, -9.95]} />
      <NFTTemplate key={3} number={3} texture={nftTextureList[3] || texture} ShowPopup={showPopup} position={[2.84, 2.32, 6.9]} />
      <NFTTemplate key={4} number={4} texture={nftTextureList[4] || texture} ShowPopup={showPopup} position={[-0.38, 2.32, 6.9]} />
      <NFTTemplate key={5} number={5} texture={nftTextureList[5] || texture} ShowPopup={showPopup} position={[3, 2.32, -9.95]}/>
      <NFTTemplate key={6} number={6} texture={nftTextureList[6] || texture} ShowPopup={showPopup} position={[9.72, 2.32, -9.95]} />
      <NFTTemplate key={7} number={7} texture={nftTextureList[7] || texture} ShowPopup={showPopup} position={[13.11, 2.32, -9.95]} />
    </group>
  )
}

useGLTF.preload('/template.glb')
export default Template

// type IProps = {
//   showPopup: Function,
//   nftLinkList: string | undefined
// }
// type IState = {
//   nftLink: string
//   nodes: any;
//   materials: any;
//   texture: any;
// }

// export default class Template extends React.Component<IProps, IState> {

//   public constructor(props: IProps) {
// 		super(props);
// 		this.state = {
//       nftLink: img,
//       // eslint-disable-next-line react-hooks/rules-of-hooks
//       nodes: useGLTF('/template.glb'),
//       // eslint-disable-next-line react-hooks/rules-of-hooks
//       materials: useGLTF('/template.glb'),
//       // eslint-disable-next-line react-hooks/rules-of-hooks
//       texture: useLoader(Three.TextureLoader, img),
// 		};

//     console.log(this.state)

// 	};
 

//   override componentDidUpdate(prevProps: IProps) {
//     if (this.props.nftLinkList && this.props.nftLinkList !== prevProps.nftLinkList) {
//       this.setState({
//         nftLink: this.props.nftLinkList,
//         // eslint-disable-next-line react-hooks/rules-of-hooks
//         texture: useLoader(Three.TextureLoader, this.state.nftLink),
//       });
//     }
//   }


    
//   override render() {
//     return (
//       <group dispose={null}>
//       <mesh geometry={this.state.nodes.Floor.geometry} material={this.state.materials['Material.002']} position={[14.08, 0, 5.5]} scale={[10, 0.3, 10]} />
//       <mesh geometry={this.state.nodes.Wall_front.geometry} material={this.state.materials['Material.001']} position={[14.16, 0, 5.7]} scale={[0.2, 2.08, 6.18]} />
//       <mesh geometry={this.state.nodes.Wall_left_1.geometry} material={this.state.materials['Material.002']} position={[15.94, 0, 8.19]} rotation={[0, 1.57, 0]} scale={[0.2, 2.08, 3.86]} />
//       <mesh geometry={this.state.nodes.Wall_left_2.geometry} material={this.state.materials['Material.002']} position={[5.74, 0, 8.19]} rotation={[0, 1.57, 0]} scale={[0.2, 2.08, 3.86]} />
//       <mesh geometry={this.state.nodes.Wall_right_1.geometry} material={this.state.materials['Material.002']} position={[5.74, 0, -9.19]} rotation={[0, 1.57, 0]} scale={[0.2, 2.08, 3.86]} />
//       <mesh geometry={this.state.nodes.Wall_right_2.geometry} material={this.state.materials['Material.002']} position={[15.94, 0, -9.19]} rotation={[0, 1.57, 0]} scale={[0.2, 2.08, 3.86]} />
//       <mesh geometry={this.state.nodes.LogoLockup.geometry} material={this.state.materials.LogoLockup} position={[14.85, 2.42, -1.42]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[3, 1, 3]} />
//       {/* NFT templates */}
//       <NFTTemplate key={1} texture={this.state.texture} ShowPopup={this.props.showPopup} position={[13.26, 2.32, 6.93]} />
//       <NFTTemplate key={2} texture={this.state.texture} ShowPopup={this.props.showPopup} position={[9.92, 2.32, 6.9]} />
//       <NFTTemplate key={3} texture={this.state.texture} ShowPopup={this.props.showPopup} position={[-0.18, 2.32, -9.95]} />
//       <NFTTemplate key={4} texture={this.state.texture} ShowPopup={this.props.showPopup} position={[2.84, 2.32, 6.9]} />
//       <NFTTemplate key={5} texture={this.state.texture} ShowPopup={this.props.showPopup} position={[-0.38, 2.32, 6.9]} />
//       <NFTTemplate key={6} texture={this.state.texture} ShowPopup={this.props.showPopup} position={[3, 2.32, -9.95]}/>
//       <NFTTemplate key={7} texture={this.state.texture} ShowPopup={this.props.showPopup} position={[9.72, 2.32, -9.95]} />
//       <NFTTemplate key={8} texture={this.state.texture} ShowPopup={this.props.showPopup} position={[13.11, 2.32, -9.95]} />
//       {/*  */}
//       <mesh geometry={this.state.nodes.Cube.geometry} material={this.state.materials.Material} position={[0, 0.34, -0.95]} scale={[0.93, 0.47, 0.94]} />
//     </group>
//     )
//   }
// }


